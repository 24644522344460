import { render, staticRenderFns } from "./Question.vue?vue&type=template&id=5a8183d4&scoped=true&"
import script from "./Question.vue?vue&type=script&lang=js&"
export * from "./Question.vue?vue&type=script&lang=js&"
import style0 from "./Question.vue?vue&type=style&index=0&lang=css&"
import style1 from "./Question.vue?vue&type=style&index=1&id=5a8183d4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a8183d4",
  null
  
)

export default component.exports