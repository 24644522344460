<!--
 * 问卷调查
-->
<template>
  <section class="Question">
      <div class="bg">
        <p>软件名称：同云CIS</p>
        <p>软件生产厂商：湖南同云医疗科技有限公司</p>
        <p>版本号：CIS4.0.9</p>
        <p>系统安全级别：三级</p>
        <p>数据存储：华为云</p>
        <p>系统定位：SAAS系统 云系统</p>
        <p>现有版本：医保版、标准版、旗舰版、终身版</p>
        <p>软件使用年限</p>
      </div>
      <div class="lists">
            <div v-for="(item,index) in listData" :key="item.id" class="items">
                <div class="tit"><span style="color:red">*</span>{{index+1}}、{{item.label}}</div>
                <div class="bot">
                    <el-radio-group v-model="item.value">
                        <el-radio border size="small" :label="1">是</el-radio>
                        <el-radio border size="small" :label="0">否</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div class="items">
                <div class="tit">20、目前系统的操作性和稳定性如何？请具体请说明！</div>
                <div>
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 3, maxRows: 10}"
                        placeholder="请输入内容"
                        v-model="formData.qt">
                    </el-input>
                </div>
            </div>
            <div class="items">
                <div class="tit">21、目前软件有哪些地方是您比较认可的，哪些地方是需要改进的？</div>
                <div>
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 3, maxRows: 10}"
                        placeholder="请输入"
                        v-model="formData.qu">
                    </el-input>
                </div>
            </div>
            <div class="items">
                <div class="tit">22、有没有需要改造新的功能或者模块？</div>
                <div>
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 3, maxRows: 10}"
                        placeholder="请输入"
                        v-model="formData.qv">
                    </el-input>
                </div>
            </div>
            <div class="items">
                <div class="tit">23、您单位常用品种有哪些？比如三九感冒灵</div>
                <div>
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 3, maxRows: 10}"
                        placeholder="请输入"
                        v-model="formData.qw">
                    </el-input>
                </div>
            </div>
      </div>
      <el-button :loading="loadings" class="submitBtn" type="primary" @click="submitBtn">提交</el-button>
  </section>
</template>

<script>
export default {
  name: 'Question',
  data(){
    return{
        loadings:false,
        formData:{},
        listData:[
            {label:'同云科技业务范围：基层医疗机构的专业管理软件+医保+专业服务+药品供应',value:undefined},
            {label:'同安医药业务范围：做全国药品批发及商业公司和连锁药房的调拨业务',value:undefined},
            {label:'您是否清楚同安医药是同云科技集团子公司',value:undefined},
            {label:'您对同云系统各模块功能以及医保刷卡流程是否熟悉',value:undefined},
            {label:'日常使用同云系统是否顺利完成日常结算或者医保刷卡',value:undefined},
            {label:'同云系统操作界面是否简单易学',value:undefined},
            {label:'您购买的系统版本是否能够满足日常操作需求',value:undefined},
            {label:'同云客服或者业务员是否对您进行过系统和医保相关培训',value:undefined},
            {label:'您对公司客服人员的服务是否还满意',value:undefined},
            {label:'您对公司业务人员的服务是否满意',value:undefined},
            {label:'您对公司的医保版、标准版和旗舰版是否了解',value:undefined},
            {label:'您是否参与过同云系统上的同安易购选购药品',value:undefined},
            {label:'你是否认为同安易购上的这个药品符合市场需求',value:undefined},
            {label:'公司客服人员是否可以快速高效的帮您解决好系统及医保问题',value:undefined},
            {label:'您对公司医保群信息是否会及时关注',value:undefined},
            {label:'对于电脑不熟悉的单位，是否愿意花小钱购买服务包让客服人员一对一的服务',value:undefined},
            {label:'公司组织大家进行系统和医保的全流程培训是否有需求',value:undefined},
            {label:'您是否清楚药品监督局和卫健委要全面实现诊疗机构电子信息化',value:undefined},
            {label:'您对国家医保新政策（家庭共济、新冠门诊、职工门诊统筹）是否了解',value:undefined}
        ]
    }
  },
  created(){
    document.title = '同云系统问卷调查表'
  },
  methods:{
    submitBtn(){
        const result = this.listData.every(res=>{
            return res.value != undefined
        })
        if(!result){
            return this.$message({
                type: 'warning',
                message: '请完成带*的选择',
                offset: 90
            })
        }
        this.axios({
            url: '/system/tclinicquestionnaire/add',
            method: 'post',
            data: {
                ...this.formData,
                qa:JSON.stringify(this.listData.map(res=> res.value))
            }
        }).then(() => {
            this.$message({
                type: 'success',
                message: '提交成功',
                offset: 90
            })
            location.reload()
        })
        .finally(() => {
            this.loadings = false
        })
    }
  }
}
</script>
<style>
    .el-message{
        min-width:90%;
        max-width:380px;
    }
</style>
<style lang="scss" scoped>
    .Question /deep/{
        background: #f0f0f0;
        text-align: left;
        padding-bottom:50px;
        .bg{
            padding: 10px;
            background: #fff;
            margin-bottom: 5px;
        }
        .items{
            margin-top: 2px;
            padding: 10px;
            background: #fff;
        }
        .tit{
            font-size: 15px;
            color: #222;
            line-height: 1.5;
            margin-bottom: 10px;
        }
        .el-radio{
            margin-right: 0px;
        }
        .bot{
            text-align: right;
        }
        .submitBtn{
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            z-index:10;
            border-radius: 0;
            padding:17px;
        }
    }
</style>
